import React from 'react'
import { Link } from 'gatsby'
import * as styles from './release-note-preview.module.scss'

type PostPreviewProps = {
  posts: any[]
  path?: string
}

const PostPreview = (props: PostPreviewProps) => {
  const { posts, path } = props
  return (
    <ul className={styles.releaseNote}>
      {posts.map((post) => {
        return (
          <li key={post.id} style={{ margin: '30px 0' }}>
            <>
              <Link to={`/${path ?? 'changelog'}/${post.slug}`}>
                <h2 className={`subtitle ${styles.changelogSubtitle}`}>
                  {post.title}
                </h2>
              </Link>
              <div className={styles.meta}>
                <time dateTime={post.meta.createdAt}>{post.meta.fromNow}</time>
              </div>
            </>
          </li>
        )
      })}
    </ul>
  )
}

export default PostPreview
